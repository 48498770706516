import { HeroRight } from "./HeroImageHandlers"
// import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { Ingress } from "../Styling/Body"
import HeroBannerProps from "../Hero/HeroTypes"

const HeroHeadline = (props: HeroBannerProps) => {
  return (
    <>
      <header>
        <div className="relative">
          <div className="flex items-center justify-center">
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <HeroRight {...props} />
            </div>
            <div className="absolute align-top content-center ">
              <h1 className="text-9xl">{props.title}</h1>
              <h2 className="text-lg">
                <Ingress>{props.ingress.ingress}</Ingress>
              </h2>
              {/* <MDXRenderer>{props.body.childMdx.body}</MDXRenderer> */}
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default HeroHeadline
