import { graphql } from "gatsby"

// import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"

// import { useIsAuthenticated } from "@azure/msal-react"

import CategoryCards from "../components/Cards/CategoryCards"

import SEO, { getSeoProps } from "../components/Common/SEO"

// import getBannerProps from "../components/Hero/getHeroBannerProps"

import HeroTopicHeadline from "../components/Hero/HeroHeadline"

/**
 * @description Bygges opp likt som de andre sidene, oversikt, ingress, visning av kort
 * kort sorteres etter nyeste først (created date)
 * @param param0
 */
const Kategori = ({ location, data }) => {
  const {
    allContentfulCategories: { nodes: allContent },
    contentfulGeneric: content,
  } = data

  allContent.sort((a, b) => a.order - b.order)

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "article")

  // Parse the content, and get the needed banner props
  // const heroBannerProps = getBannerProps(content)

  return (
    <>
      <SEO {...helmetProps} />
      <main>
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <header>
            <HeroTopicHeadline {...content} />
          </header>

          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {allContent.map((category) => {
              return <CategoryCards key={category.id} data={category} />
            })}
          </div>
        </div>
      </main>
    </>
  )
}

export const categoryQuery = graphql`
  {
    allContentfulCategories(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        slug
        id
        videoUrl
        contentful_id
        node_locale
        createdAt(formatString: "YYYY-MM-D")
        updatedAt(formatString: "YYYY-MM-D")
        generic {
          metadataDescription
          heroImage {
            id
            public_id
            context {
              custom {
                alt
                Credits
              }
            }
            url
          }
          ingress {
            ingress
            childMdx {
              timeToRead
            }
          }
          body {
            childMdx {
              timeToRead
              body
            }
          }
        }
        category
      }
    }
    contentfulGeneric(slug: { eq: "kategorier" }, node_locale: { eq: "nb-NO" }) {
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
      heroImage {
        url
        id
        public_id
        secure_url
      }
      node_locale
      title
    }
  }
`

export default Kategori
