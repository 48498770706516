import { Link } from "gatsby-plugin-react-i18next"
import { HeroRight } from "../Hero/HeroImageHandlers"
import { Ingress } from "../Styling/Body"
import HeroBannerProps from "../Hero/HeroTypes"

const CategoryCards = ({ data }) => {
  const heroProps: HeroBannerProps = {
    videoUrl: data.videoUrl,
    heroImage: data.generic?.heroImage,
  }

  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-shrink-0">
        <HeroRight {...heroProps} />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm font-medium text-indigo-600">
            <a href="#" className="hover:underline">
              Video
            </a>
          </p>
          <Link to={`/nb/kategori/${data.slug}/`}>
            <p className="text-xl font-semibold text-gray-900">{data.category}</p>
            <p className="mt-3 text-base text-gray-500">
              {data.generic !== null && data.generic.ingress !== undefined && data.generic.ingress !== null ? (
                <Ingress>{data.generic.ingress.ingress}</Ingress>
              ) : (
                <div></div>
              )}
            </p>
          </Link>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            <a href="#">
              {/* <span className="sr-only">{data.author.name}</span> */}
              <img
                className="h-10 w-10 rounded-full"
                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
            </a>
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">
              <a href="#" className="hover:underline">
                {/* {data.author.name} */}
              </a>
            </p>
            <div className="flex space-x-1 text-sm text-gray-500">
              <time dateTime="2020-03-10">{data.createdAt}</time>
              <span aria-hidden="true">&middot;</span>
              {/* <span>Lesetid: {data.body.childMarkdownRemark.timeToRead} min</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryCards
